<nav #navbar class="navbar navbar-expand-lg navbar-transparent navbar-absolute">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button
          mat-raised-button
          (click)="minimizeSidebar()"
          class="btn btn-just-icon btn-white btn-fab btn-round"
        >
          <i class="material-icons text_align-center visible-on-sidebar-regular"
            >more_vert</i
          >
          <i
            class="material-icons design_bullet-list-67 visible-on-sidebar-mini"
            >view_list</i
          >
        </button>
      </div>
      <a class="navbar-brand" href="{{ getPath() }}"> {{ getTitle() }} </a>
    </div>
    <button
      mat-button
      class="navbar-toggler btn-no-ripple"
      type="button"
      (click)="sidebarToggle()"
    >
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navigation">
     <form *ngIf="userRole !== 'VENDOR'"  class="navbar-form">
                <div class="input-group no-border">
                    <input type="text" name="posearch" class="form-control" [(ngModel)]="searchpo" [placeholder]="'Search ' + getPlaceholderText() + '...'">
                    <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon" [disabled]="searchpo.length < 4 || searching" (click)="searchingPO()">
          <i *ngIf="!searching" class="fa fa-search" aria-hidden="true"></i>
          <i *ngIf="searching" class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
            <div class="ripple-container"></div>
          </button>
          &nbsp;&nbsp;
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Example icon-button with a menu"
          >
            <i class="fa fa-filter" aria-hidden="true"></i>
          </button>
          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              [disabled]="typesearch === 'pi'"
              (click)="typesearch = 'pi'"
            >
              <i class="fa fa-shopping-cart" aria-hidden="true"></i>
              <span>&nbsp; Search PI</span>
            </button>
            <button
              mat-menu-item
              [disabled]="typesearch === 'po'"
              (click)="typesearch = 'po'"
            >
              <i class="fa fa-shopping-cart" aria-hidden="true"></i>
              <span>&nbsp; Search PO</span>
            </button>
            <button
              mat-menu-item
              [disabled]="typesearch === 'cid'"
              (click)="typesearch = 'cid'"
            >
              <i class="fa fa-braille" aria-hidden="true"></i>
              <span>&nbsp; Search CID </span>
            </button>
            <button
              mat-menu-item
              [disabled]="typesearch === 'ticket'"
              (click)="typesearch = 'ticket'"
            >
              <i class="fa fa-ticket" aria-hidden="true"></i>
              <span>&nbsp; Search Tickets</span>
            </button>
          </mat-menu>
        </div>
      </form>
      <ul class="navbar-nav">
        
        <li *ngIf="userRole !== 'VENDOR'"  class="nav-item dropdown">
          <a class="nav-link" href="#HA" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="material-icons">notifications</i>
            <span class="notification">{{
              _globalNumberNotificationService.numberNotifications
            }}</span>
            <p>
              <span class="d-lg-none d-md-block">Some Actions</span>
            </p>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdownMenuLink"
          >
            <a
              class="dropdown-item"
              *ngFor="let notification of notificationsData"
              href="{{ notification.url }}"
              target="_blank"
              [innerHTML]="notification.text"
              (click)="marknotification(notification)"
            ></a>
            <a class="dropdown-item" [routerLink]="['/notifications']"
              ><span class="material-icons"> notifications_active </span> View
              all</a
            >
          </div>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link"
            href="#HA"
            id="navbarDropdownMenuAccount"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="material-icons">person</i>
            <p>
              <span class="d-lg-none d-md-block">Account</span>
            </p>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdownMenuAccount"
          >
            <a class="dropdown-item" [routerLink]="['/pages/profile/1']"
              >My Profile</a
            >
            <a class="dropdown-item" [routerLink]="['/pages/editprofile/1']"
              >Edit Profile</a
            >
            <a
              class="dropdown-item"
              [routerLink]="['/pages/userchangepassword', this.privilegio.sub]"
              >Change Password</a
            >
            <a class="dropdown-item" (click)="logout()">Sign Out</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
